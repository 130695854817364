import React from 'react';
import Helmet from 'react-helmet';

import {SignInForm} from "./SignInForm";
import "./SignIn.css";
import {SignIn as SignInComponent} from "benlux-ecommerce-ui";
import {ToSignUpForm} from "../SignUp/SignUpForm";

const SignIn = ({history, location}) => {
  const {search} = location;

  const queryParams = new URLSearchParams(search);
  const next = queryParams.get('next') || '/';
  const nextSignUp = queryParams.get('next') || '/inscription';

  return (
    <>
      <Helmet>
        <title>Connexion</title>
      </Helmet>
      <SignInComponent
        children={
          <SignInForm
            id="sign-in-page"
            onCompleted={() => history.replace(next)}
          />
        }
        childrenSignUp={
          <ToSignUpForm onCompleted={(value) => history.replace({
            pathname: '/inscription',
            state: {email: value.email}
          })}/>
        }
      />
    </>
  )
};

export default SignIn;
